import React from 'react'

import SEO from '../../components/seo'
import Responsible from '../../components/ResponsibleGaming/ResponsibleGaming'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { RESPONSIBLE_GAMING as RESPONSIBLE_GAMING_LINK } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Responsible Gaming',
    url: RESPONSIBLE_GAMING_LINK,
  },
]

const ResponsibleGaming: React.FC = () => {
  return (
    <div>
      <SEO
        title="Read How Mega Provide Responsible Gaming To Its Users"
        description="Learn how to play responsibly on Mega, an entertainment platform that encourages its users to have fun while playing, learning and earning."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Responsible />
    </div>
  )
}

export default ResponsibleGaming
