// list of all internal links

import { formUrlPath } from '../utils/urlUtils'

export const GAMES = '/games/'
export const EARN_MONEY = '/earn-money/'
export const GAMBLING = '/gambling/'
export const LISTINGS = '/listings/'

export const CARDS = `/cards/`
export const CASUAL = `/casual/`
export const TRIVIA = `/trivia/`
// export const CARDS = `https://getmega.onelink.me/shOD/OrganicWebsiteCards/`
// export const CASUAL = `https://getmega.onelink.me/shOD/OrganicWebsitesCasual/`
// export const TRIVIA = `https://getmega.onelink.me/shOD/OrganicWebsitesTrivia/`

export const POKER = `/poker/`
export const RUMMY = `/rummy/`
export const HINDI_HOME = `/hi/`
export const POKER_HINDI = `${HINDI_HOME}poker/`
export const RUMMY_HINDI = `${HINDI_HOME}rummy/`
export const RUMMY_VARIANTS = `/rummy/learn/rummy-variants/`
export const CARROM = `${CASUAL}carrom/`
export const POOL = `${CASUAL}8-ball-pool/`
export const CHESS = `${CASUAL}chess/`
export const HOP_POP = `${CASUAL}chinese-checkers-hop-pop/`
export const ABC_RUMMY = `${CASUAL}abcrummy/`
export const DOTS_DASH = `${CASUAL}dots-dash/`
export const WARSHIP = `${CASUAL}warship/`
export const FRUIT_KAAT = `${CASUAL}match-3-fruitkaat/`
export const GKGURU = `${TRIVIA}gk/`
export const ONE23 = `${TRIVIA}123-maths-quiz/`
export const PICME = `${TRIVIA}pic-me/`
// export const CRICKET = `${CASUAL}cricket/`

// export const POKER = `https://getmega.onelink.me/shOD/OrganicWebsitePoker/`
// export const RUMMY = `https://getmega.onelink.me/shOD/OrganicWebsiteRummy/`
// export const CARROM = `https://getmega.onelink.me/shOD/OrganicWebsiteCarrom/`
// export const POOL = `https://getmega.onelink.me/shOD/OrganicWebsiteGoPool/`
// export const HOP_POP = `https://getmega.onelink.me/shOD/OrganicWebsiteHopPop/`
// export const ABC_RUMMY = `https://getmega.onelink.me/shOD/OrganicWebsiteABCRummy/`
// export const DOTS_DASH = `https://getmega.onelink.me/shOD/OrganicWebsiteDotsDash/`
// export const WARSHIP = `https://getmega.onelink.me/shOD/OrganicWebsiteWarship/`
// export const FRUIT_KAAT = `https://www.getmega.com/casual/match-3-fruitkaat/`
// export const GKGURU = `https://getmega.onelink.me/shOD/OrganicWebsiteGkGuru/`
// export const ONE23 = `https://getmega.onelink.me/shOD/OrganicWebsite123/`
// export const PICME = `https://www.getmega.com/trivia/pic-me//`

export const POKER_LEARN = `${POKER}learn/`
export const POKER_TERMS = `${POKER}terms/`
export const POKER_STRATEGY = `${POKER}strategy/`
export const POKER_BOOKS = `${POKER}books/`
export const POKER_HANDS = `${POKER}hands/`
export const POKER_HIERARCHY = `${POKER}poker-hierarchy/`
export const POKER_CARDS = `${POKER}/poker-cards/`
export const POKER_ODDS = `${POKER}poker-odds-calculator/`
export const POKER_GAME_DOWNLOAD = `${POKER}poker-app-download/`
export const POKER_RMG = `${POKER}rmg/`
export const POKER_VARIANTS = `${POKER}learn/types-of-poker-games/`
export const POKER_STORIES = `/web-stories${POKER}`
export const POKER_PRACTICE = `/poker-free-practice-app/`
export const POKER_TEXAS_HOLDEM = `${POKER_LEARN}learn-how-to-play-texas-holdem/`
export const POKER_TEXAS_HOLDEM_HINDI = formUrlPath(
  `${HINDI_HOME}${POKER_TEXAS_HOLDEM}`
)
export const POKER_OMAHA = `${POKER_LEARN}learn-omaha-poker/`
export const POKER_OMAHA_HINDI = formUrlPath(`${HINDI_HOME}${POKER_OMAHA}/`)
export const POKER_FIVE_CARD = `${POKER_LEARN}how-to-play-5-card-poker/`
export const POKER_PLAY_BADUGI = `${POKER_LEARN}how-to-play-and-rules-of-badugi/`
export const POKER_CHINESE = `${POKER_LEARN}how-to-play-chinese-poker/`
export const POKER_HORSE = `${POKER_LEARN}how-to-play-horse-poker/`
export const POKER_PINEAPPLE = `${POKER_LEARN}how-to-play-pineapple-poker/`
export const POKER_SUPER_TEXAS_HOLDEM = `${POKER_LEARN}how-to-play-rules-of-super-texas-holdem/`
export const POKER_SHORT_DECK = `${POKER_LEARN}how-to-play-short-deck-poker/`
export const POKER_OPEN_FACE_CHINESE = `${POKER_LEARN}open-face-chinese-poker/`
export const POKER_RAZZ_OMAHA = `${POKER_LEARN}razz-omaha/`
export const POKER_TRIPLE_DRAW = `${POKER_LEARN}rules-of-2-7-triple-draw/`
export const POKER_STUD = `${POKER_LEARN}rules-of-7-card-stud-poker/`
export const POKER_BASICS = `${POKER_LEARN}basics-of-poker/`
export const POKER_PLAY_LIKE_PRO = `${POKER_LEARN}how-to-play-poker-like-a-pro/`
export const POKER_RULES = `${POKER_LEARN}all-in-rules-in-poker/`
export const POKER_CHIPS = `${POKER_LEARN}poker-chips-and-their-values/`
export const POKER_TYPES = `${POKER_LEARN}types-of-poker-games/`
export const POKER_HI_LO_OMAHA = `${POKER_LEARN}how-to-play-hi-lo-omaha-poker/`
export const POKER_INDIAN = `${POKER_LEARN}how-to-play-indian-poker/`
export const POKER_STRIP = `${POKER_LEARN}how-to-play-strip-poker/`
export const POKER_FREE_PRACTICE = `/poker-free-practice-app/`
export const POKER_GLOSSARY = `${POKER}terms/`
export const POKER_TOOLS = `${POKER_LEARN}top-10-best-poker-tools-and-software-2023`
export const POKER_TOURNAMENT = `${POKER_STRATEGY}how-to-play-in-poker-tournaments/`
export const POKER_APP_DOWNLOAD = `${POKER}poker-app-download/`

export const RUMMY_LEARN = `${RUMMY}learn/`
export const RUMMY_RMG = `${RUMMY}rmg/`
export const RUMMY_STORIES = `/web-stories${RUMMY}`
export const RUMMY_PRACTICE = `/rummy-free-practice-app/`

export const STORIES = `/web-stories/`

export const NEWS = `/news/`

export const CARROM_LEARN = `${CARROM}learn/`

export const POOL_LEARN = `${POOL}learn/`

export const CHESS_LEARN = `${CHESS}learn/`

export const CRICKET_LEARN = `${CASUAL}learn-cricket/`

export const COMPARISON_BLOGS = `/compare/`

export const REAL_MONEY_GAMES_BLOGS = `/real-money-gaming-apps/`

export const CONTACT_US = `/contact-us/`
export const REFER_EARN = `/refer-and-earn/`

export const HELP_CENTRE = '/help-centre/'
export const HC_GETTING_STARTED = `${HELP_CENTRE}getting-started/`
export const HC_PAYMENTS_AND_WITHDRAWALS = `${HELP_CENTRE}payments-withdrawals/`
export const HC_KYC = `${HELP_CENTRE}kyc/`
export const HC_CONTESTS_GAMEPLAYS = `${HELP_CENTRE}contests-gameplays/`
export const HC_GAME_RULES = `${HELP_CENTRE}game-rules/`
export const HC_PROMOTIONS_OFFERS = `${HELP_CENTRE}promotions-offers/`

export const FAQS = `/faqs/`
export const FAQS_CARDS = `${FAQS}cards/`
export const FAQS_CASUAL = `${FAQS}casual/`
export const FAQS_TRIVIA = `${FAQS}trivia/`
export const FAQS_CONTESTS = `${FAQS}contests/`
export const FAQS_PAYMENTS_WITHDRAWALS = `${FAQS}payments-withdrawals/`

export const POKER_FAQS = `${POKER}faqs/`
export const POKER_FAQS_STRATEGIES = `${POKER_FAQS}strategies/`
export const POKER_FAQS_TOURNAMENTS = `${POKER_FAQS}tournaments/`
export const POKER_FAQS_SOFTWARE = `${POKER_FAQS}software/`
export const POKER_FAQS_POKER_DOWNLOAD = `${POKER_FAQS}download/`
export const POKER_FAQS_POKER_PAYMENTSBONUSES = `${POKER_FAQS}paymentsbonuses/`
export const POKER_FAQS_HELP = `${POKER_FAQS}help/`

export const ABOUT_US = '/about-us/'

export const TESTIMONIALS = '/testimonials/'

export const FAIRPLAY_POLICY = '/fairplay-policy/'

export const RESPONSIBLE_GAMING = '/responsible-gaming/'

export const CERTIFICATIONS = '/certifications/'

export const PRIVACY_POLICY = '/privacy-policy/'

export const TERMS = '/terms-of-use/'

export const GANGS_OF_GAMERS = '/gangs-of-gamers/'

export const DOWNLOAD = '/install-real-money-gaming-app/'

export const SITEMAP = '/sitemap/'

export const AUTHORS = '/authors/'

export const AUTHOR = '/author/'

export const DEPOSIT_OFFERS = '/deposit-offers/'

export const WINNERS = '/winners/'

export const LEADERBOARD = '/leaderboard/'

export const RUMMY_TDS = '/rummy-tds-policy/'

export const POKER_TDS = '/poker-tds-policy/'

export const NEW_GST = '/new-gst/'

export const APK_URL = 'https://www.getmega.com/latest-social/'

export const PLAY_POKER_URL = 'https://getmega.onelink.me/shOD/GMPoker'

export const PLAY_RUMMY_URL = 'https://getmegarummy.onelink.me/kEtj/GMRummy'

export const CHINESE_POKER = `${POKER_LEARN}how-to-play-chinese-poker/`
export const FACE_CARD_POKER = `${POKER_LEARN}face-cards-in-poker/`
export const FLOP_TURN_RIVER_POKER = `${POKER_LEARN}flop-turn-the-river-in-poker/`
